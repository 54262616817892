<script>
/**
 * Sources Component
 */
export default {
  data() {
    return {
      source1: {
        series: [
          {
            data: [23, 32, 27, 38, 27, 32, 27, 34, 26, 31, 28]
          }
        ],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            width: [3],
            curve: "smooth"
          },
          colors: ["#5664d2"],
          tooltip: {
            fixed: {
              enabled: false
            },
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function() {
                  return "";
                }
              }
            },
            marker: {
              show: false
            }
          }
        }
      },
      source2: {
        series: [
          {
            data: [24, 62, 42, 84, 63, 25, 44, 46, 54, 28, 54]
          }
        ],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            width: [3],
            curve: "smooth"
          },
          colors: ["#5664d2"],
          tooltip: {
            fixed: {
              enabled: false
            },
            x: {
              show: false
            },
            y: {
              title: {}
            },
            marker: {
              show: false
            }
          }
        }
      },
      source3: {
        series: [
          {
            data: [42, 31, 42, 34, 46, 38, 44, 36, 42, 32, 54]
          }
        ],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            width: [3],
            curve: "smooth"
          },
          colors: ["#5664d2"],
          tooltip: {
            fixed: {
              enabled: false
            },
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function() {
                  return "";
                }
              }
            },
            marker: {
              show: false
            }
          }
        }
      },
      startDate : "",
      endDate : "",
      roleId: JSON.parse(localStorage.getItem('user')).data.menu_role_id || 0,
      email: JSON.parse(localStorage.getItem('user')).data.email,
      partner_id: JSON.parse(localStorage.getItem('user')).data.id,
    };
  },
  methods:{
    getDataGame(){
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var startDate = date.setDate(firstDay.getDate());

      this.$axios
      .post(this.$apiService + "api/v1/getDataGame", {
          start_date: new Date(startDate).toISOString().substring(0, 10),
          end_date: new Date().toISOString().substring(0, 10),
          role_id: this.roleId,
          email: this.email,
          partner_id: this.partner_id
      })
      .then(response =>{
          console.log(response, "getDataGame");
      })
    }
  },
  mounted(){
    this.getDataGame();
  }
};
</script>

<template>
  <div class="card c-5">
    <div class="card-body mt-3">
      <h4 class="card-title mb-3">TOP 5 GAMES</h4>
      <div>
        <div class="float-right mb-2 mr-4" style="margin-right:4rem !important">Players</div>
        <div class="table-responsive mt-3">
          <table class="table table-hover mb-0 table-centered table-nowrap">
            <tbody>
              <tr>
                <td style="width: 60px;">
                  <div class="avatar-xs">
                    <div class="avatar-title rounded-circle bg-light">
                      <img src="@/assets/images/companies/img-1.png" alt height="20" />
                    </div>
                  </div>
                </td>

                <td>
                  <h5 class="font-size-14 mb-0">Tembak!</h5>
                </td>
                <!-- <td>
                  <apexchart
                    class="apex-charts"
                    height="35"
                    dir="ltr"
                    :series="source1.series"
                    :options="source1.chartOptions"
                    type="line"
                    width="80"
                  ></apexchart>
                </td> -->
                <td>
                  <p class="text-muted mb-0">30</p>
                </td>
              </tr>
              <tr>
                <td style="width: 60px;">
                  <div class="avatar-xs">
                    <div class="avatar-title rounded-circle bg-light">
                      <img src="@/assets/images/companies/img-1.png" alt height="20" />
                    </div>
                  </div>
                </td>

                <td>
                  <h5 class="font-size-14 mb-0">Macet</h5>
                </td>
                <!-- <td>
                  <apexchart
                    class="apex-charts"
                    height="35"
                    dir="ltr"
                    :series="source1.series"
                    :options="source1.chartOptions"
                    type="line"
                    width="80"
                  ></apexchart>
                </td> -->
                <td>
                  <p class="text-muted mb-0">27</p>
                </td>
              </tr>
              <tr>
                <td style="width: 60px;">
                  <div class="avatar-xs">
                    <div class="avatar-title rounded-circle bg-light">
                      <img src="@/assets/images/companies/img-1.png" alt height="20" />
                    </div>
                  </div>
                </td>

                <td>
                  <h5 class="font-size-14 mb-0">Ular Tambah</h5>
                </td>
                <!-- <td>
                  <apexchart
                    class="apex-charts"
                    height="35"
                    dir="ltr"
                    :series="source1.series"
                    :options="source1.chartOptions"
                    type="line"
                    width="80"
                  ></apexchart>
                </td> -->
                <td>
                  <p class="text-muted mb-0">20</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="avatar-xs">
                    <div class="avatar-title rounded-circle bg-light">
                      <img src="@/assets/images/companies/img-2.png" alt height="20" />
                    </div>
                  </div>
                </td>
                <td>
                  <h5 class="font-size-14 mb-0">Ayam Jagoan</h5>
                </td>

                <!-- <td>
                  <apexchart
                    class="apex-charts"
                    height="35"
                    dir="ltr"
                    :series="source2.series"
                    :options="source2.chartOptions"
                    type="line"
                    width="80"
                  ></apexchart>
                </td> -->
                <td>
                  <p class="text-muted mb-0">15</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="avatar-xs">
                    <div class="avatar-title rounded-circle bg-light">
                      <img src="@/assets/images/companies/img-3.png" alt height="20" />
                    </div>
                  </div>
                </td>
                <td>
                  <h5 class="font-size-14 mb-0">Empat</h5>
                </td>
                <!-- <td>
                  <apexchart
                    class="apex-charts"
                    height="35"
                    dir="ltr"
                    :series="source3.series"
                    :options="source3.chartOptions"
                    type="line"
                    width="80"
                  ></apexchart>
                </td> -->
                <td>
                  <p class="text-muted mb-0">14</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- <div class="text-center mt-4">
          <a href="#" class="btn btn-primary btn-sm">View more</a>
        </div> -->
      </div>
    </div>
  </div>
</template>