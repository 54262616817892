<script>
import DatePicker from "vue2-datepicker";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";

import Stat from "@/views/pages/dashboard/widget";
import RevenueAnalytics from "@/views/pages/dashboard/revenue";
import SalesAnalytics from "@/views/pages/dashboard/sales-analytics";
//import EarningReport from "./earning";
import Sources from "@/views/pages/dashboard/sources";
//import RecentActivity from "./recent-activity";
//import RevenueLocation from "./revenue-location";
//import Chat from "./chat";
//import Transaction from './transaction';
import GameCategory from '@/views/pages/dashboard/game-category';

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Stat,
    RevenueAnalytics,
    SalesAnalytics,
    //EarningReport,
    Sources,
    //RecentActivity,
    //RevenueLocation,
    //Chat,
    //Transaction,
    GameCategory,
    DatePicker
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Coregames"
        },
        {
          text: "Dashboard",
          active: true
        }
      ],
      roleId: JSON.parse(localStorage.getItem('user')).data.menu_role_id || 0,
      setMonth: '',
      setYear:'',
      daterange:'',
      startDate:"",
      endDate:"",
      

    };
  },
  methods:{
    setDate(){
      var date = new Date();
      // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      // var startDate = date.setDate(firstDay.getDate());
      this.startDate = date.getFullYear()+'-'+(date.getMonth()+1)+'-01';
      this.endDate = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();

      console.log(this.endDate);
    },
    setStartDate(e){
        console.log(e);
        this.startDate = e;
    },
    setEndDate(e){
        console.log(e);
        this.endDate = e;
    },
    filterDate(){
      if(this.roleId == 2) {
        this.$refs.widget.getVisitor();
      }else{
        this.$refs.widget.activeSubs();
      }
    }
  },
  mounted(){
    this.setDate();
    //console.log(this.setMonth, 'dashboard');
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-3">
      <div class="form-group mb-3">
                <label>Start Date</label>
                <br />
                <date-picker v-model="startDate" format="Y-M-DD" value-type="format" :first-day-of-week="1" lang="en" placeholder="Select Date" @change="setStartDate"></date-picker>
              </div>
      </div>
      <div class="col-xl-3">
      <div class="form-group mb-3">
                <label>End Date</label>
                <br />
                <date-picker v-model="endDate" format="Y-M-DD" value-type="format" :first-day-of-week="1" lang="en" placeholder="Select Date" @change="setEndDate"></date-picker>
              </div>
      </div>
      <div class="col-xl-2">
        <button type="button" class="btn btn-primary btn-sm" style="margin-top :2rem" @click.prevent="filterDate">Filter</button>
      </div>       
    </div>    
    <div class="row">
      <div class="col-xl-12">
        <Stat ref="widget" v-if="roleId===2 || roleId===1" :startDate="startDate" :endDate="endDate"/>
        
      </div>
    </div>
    <div class="row">
    <div class="col-lg-12">
        <RevenueAnalytics v-if="roleId===1 || roleId===2" />
      </div>
    </div>  
    <div class="row">
        <div class="col-xl-4">
          <SalesAnalytics />
        </div>
        <div class="col-xl-4">
          <GameCategory />
        </div>
        <div class="col-xl-4">
        <Sources />
        </div>
      </div>
      <div class="row">
      
    </div>  
    <!-- <div class="row">
      <div class="col-lg-4">
        <Sources />
      </div>
      <div class="col-lg-4">
        <RecentActivity />
      </div>
      <div class="col-lg-4">
        <RevenueLocation />
      </div>
    </div> -->
    <!-- <div class="row">
      <div class="col-lg-12">
        <Transaction />
      </div>
    </div> -->
  </Layout>
</template>