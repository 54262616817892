<script>
/**
 * Widget Component
 */
export default {
  data() {
    return {
      //statData : []
      statData: [
        {
          title: "Active Subscriber",
          //icon: "ri-stack-line",
          value: "1452",
          color_card: "",
          //subvalue: " 2.4% "
        },
        {
          title: "Number of Game Play",
          //icon: "ri-game-line",
          value: "$ 38452",
          color_card:"",
          token: " 2.4% ",
          nontoken: "3%",
        },
        {
          title: "New Players",
          //icon: "ri-briefcase-4-line",
          value: "15",
          color_card: "bg-danger",
          //subvalue: " 2.4% "
        },
      ],
      activeSub:0,
      listActiveSubs: [],
      // startDate : "",
      // endDate : "",
      newPlayer:0,
      roleId: JSON.parse(localStorage.getItem('user')).data.menu_role_id || 0,
      email: JSON.parse(localStorage.getItem('user')).data.email,
      partner_id: JSON.parse(localStorage.getItem('user')).data.id,
    };
  },
  props:[
    'startDate',
    'endDate'
  ],
  methods: {
    setStartdate(){
      var date = new Date();
      // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      // var startDate = date.setDate(firstDay.getDate());


      this.startDate = date.getFullYear()+'-'+(date.getMonth()+1)+'-01';
      this.endDate = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
    },
    activeSubs(){
      // var date = new Date();
      // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      // var startDate = date.setDate(firstDay.getDate());
      
      this.$axios
      .post(this.$apiService + "api/v1/reportSubs", {
                // start_date: new Date(startDate).toISOString().substring(0, 10),
                // end_date: new Date().toISOString().substring(0, 10),
                start_date: this.startDate,
                end_date: this.endDate,
                op_id: this.opId
              })
      .then(response => {
        console.log(response, 'cek response')
        if (response.data.data.length != 0) {
                this.listActiveSubs = response.data.data;

                this.listActiveSubs.forEach(item => {
                  this.activeSub += item.total;
                });
              } else {
                this.activeSub = 0;
              }
              this.statData[0].value = this.listActiveSubs[0].total

              console.log(this.listActiveSubs);
      })
      .catch(error => {
            console.log(error);
          });
    },
    numberOfGamePlay(){
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var startDate = date.setDate(firstDay.getDate());
      
      this.$axios
      .post(this.$apiService + "api/v1/numberOfGamePlay", {
                start_date: new Date(startDate).toISOString().substring(0, 10),
                end_date: new Date().toISOString().substring(0, 10),
                // start_date: this.startDate,
                // end_date: this.endDate,
                op_id: this.opId
              })
      .then(response => {
        console.log(response, 'numberofgameplay')
        this.numberOfGamePlay =
                response.data.data.user_token +
                response.data.data.user_nontoken;
              this.numberOfGamePlay_token = response.data.data.user_token;
              this.numberOfGamePlay_nontoken = response.data.data.user_nontoken;
              this.numberOfGamePlay_token_avg =
                response.data.data.avg_usertoken;
              this.numberOfGamePlay_nontoken_avg =
                response.data.data.avg_usernontoken;

              console.log(this.numberOfGamePlay);
              this.statData[1].value = this.numberOfGamePlay
              this.statData[1].token = this.numberOfGamePlay_token
              this.statData[1].nontoken = this.numberOfGamePlay_nontoken
      })
      .catch(error => {
            console.log(error);
          });    
    },
    newPlayers(){
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var startDate = date.setDate(firstDay.getDate());
      
      this.$axios
      .post(this.$apiService + "api/v1/newPlayers", {
                start_date: new Date(startDate).toISOString().substring(0, 10),
                end_date: new Date().toISOString().substring(0, 10),
                // start_date: this.startDate,
                // end_date: this.endDate,
                op_id: this.opId
              })
      .then(response => {
        console.log(response, 'newplayers')
        if (response.data.data.length > 0) {
              let datas = response.data.data;
              datas.forEach(item => {
                  this.newPlayer += item.total;
                });

              //console.log(this.newPlayers);
              } else {
                this.newPlayer = 0;
              }

              this.statData[2].value = this.newPlayer
              

              console.log(this.newPlayers,'NewPlayers');
      })
      .catch(error => {
            console.log(error);
          });
    },
    getVisitor(){
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var startDate = date.setDate(firstDay.getDate());
      
      this.$axios
      .post(this.$apiService + "api/v1/getVisitor", {
                start_date: new Date(startDate).toISOString().substring(0, 10),
                end_date: new Date().toISOString().substring(0, 10),
                role_id: this.roleId,
                email: this.email,
              })
      .then(response => {
        console.log(response, 'getVisitor')
        if (response.data.data.length > 0) {
              let datas = response.data.data;
              datas.forEach(item => {
                  this.visitor = item.total;
                  //console.log(item.total, 'test');
                });

              console.log(datas);
              } else {
                this.visitor = 0;
              }

              this.statData[1].title = 'Players';  
              this.statData[1].value = this.visitor;
              this.statData[1].color_card = 'bg-success'

              console.log(this.visitor,'visitor');
      })
      .catch(error => {
            console.log(error);
          });
    },
    // getRevenue(){
    //   var date = new Date();
    //   var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    //   var startDate = date.setDate(firstDay.getDate());
      
    //   this.$axios
    //   .post(this.$apiService + "api/v1/getRevenue", {
    //             start_date: new Date(startDate).toISOString().substring(0, 10),
    //             end_date: new Date().toISOString().substring(0, 10),
    //             role_id: this.roleId,
    //             email: this.email,
    //             partner_id: this.partner_id       
    //           })
    //   .then(response => {
    //     console.log(response, 'getRevenue')
    //     if (response.data.data.length > 0) {
    //           let datas = response.data.data;
    //           datas.forEach(item => {
    //               this.totRevenue = item.total;
    //               //console.log(item.total, 'test');
    //             });

    //           console.log(datas);
    //           } else {
    //             this.totRevenue = 0;
    //           }

    //           this.statData[1].title = 'Revenue';  
    //           this.statData[1].value = this.totRevenue;

    //           console.log(this.totRevenue,'Revenue');
    //   })
    //   .catch(error => {
    //         console.log(error);
    //       });
    // },
    getGamePlayed(){
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var startDate = date.setDate(firstDay.getDate());

      this.$axios
      .post(this.$apiService + "api/v1/getGamePlayed", {
          start_date: new Date(startDate).toISOString().substring(0, 10),
          end_date: new Date().toISOString().substring(0, 10),
          role_id: this.roleId,
          email: this.email,
          partner_id: this.partner_id
      })
      .then(response => {
        //console.log(response, 'getGamePlayed')
          if (response.data.data.total.length > 0) {
              let datas = response.data.data.total;
              datas.forEach(item => {
                this.totGame = item.total;
              })
          } else {
                this.totGame = 0;
              }
              
              this.statData[0].title = 'Game Played';  
              this.statData[0].value = this.totGame;
              this.statData[0].color_card = 'bg-primary'
              
      })
      .catch(error => {
            console.log(error);
          });
    }
  },
  mounted(){
    if(this.roleId=== 1){
      this.setStartdate();
      this.activeSubs();
      this.numberOfGamePlay();
      this.newPlayers();
    }else if(this.roleId === 3){
      this.getVisitor();
      this.getGamePlayed();
      console.log(this.email);
    }else{
      this.getVisitor();
      this.getGamePlayed();
      //this.getRevenue();
      //console.log(this.email);
    }
      

  }
};
</script>

<template>
  <div class="row">
    <div class="col-md-4" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div :class="`card-body ${data.color_card}`">
          <div class="media">
            <div class="media-body overflow-hidden">
              <h4 class="mb-0 text-light text-center font-size-24">{{data.value}}</h4>
              <p class="text-truncate font-size-14 mb-2 text-light text-center">{{data.title}}</p>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>
        <div class="text-truncate" v-if="data.title === 'Number of Game Play'"> 
        <div class="card-body border-top py-3">
             
            <span class="badge badge-soft-success font-size-11">
              <i class="mdi mdi-menu-up"></i>
              {{data.token}}
            </span>
            <span class="text-muted ml-2">Token</span>
            <span class="badge badge-soft-success font-size-11">
              <i class="mdi mdi-menu-up"></i>
              {{data.nontoken}}
            </span>
            <span class="text-muted ml-2">Non Token</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>