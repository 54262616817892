<script>
/**
 * Game Category component
 */

export default {
  data() {
    return {
        series: [29, 37, 24, 7],
        chartOptions: {
        labels: ["Puzzle", "Hyper Casual", "casual", "Racing"],
        colors: ["#1cbb8c", "#5664d2", "#fcb92c", "#4aa3ff"],
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0,
            offsetY: -10
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    height: 240
                },
                legend: {
                    show: false
                },
            }
        }]
    },
    roleId: JSON.parse(localStorage.getItem('user')).data.menu_role_id || 0,
    email: JSON.parse(localStorage.getItem('user')).data.email,
    partner_id: JSON.parse(localStorage.getItem('user')).data.id,
    };
  },
  methods:{
      getGameCategory(){
            // var date = new Date();
            // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            // var startDate = date.setDate(firstDay.getDate());


            this.$axios
            .post(this.$apiService + "api/v1/getGameCategory", {
                role_id: this.roleId,
                email: this.email,
                partner_id : this.partner_id
            })
            .then(response => {
                    console.log(response);
            })
            .catch(error => {
            console.log(error);
          });
      }
  },
  mounted(){
      this.getGameCategory();
      //console.log(this.chartOptions, "pie Chart");
  }
};
</script>

<template>
  <div class="card c-5">
      <div class="card-body mt-4">
          <h4 class="card-title mb-4">Games Categories</h4>
            <!-- Pie Chart -->
            <apexchart
              class="apex-charts"
              height="320"
              type="pie"
              dir="ltr"
              :series="this.series"
              :options="this.chartOptions"
            ></apexchart>
      </div>
  </div>
</template>